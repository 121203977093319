import { Skeleton } from "components/ui/skeleton";
import {
  MenuItemFormPermissionMutateSchema,
  menuItemFormPermissionMutateSchema,
  useMenuItemFormPermissionMutate,
  MenuItemSchema,
} from "hooks/useMenu";
import { Permission, usePermissionGetByGroups } from "hooks/useRole";
import { toast } from "react-toastify";
import MenuItemFormPermissionEditor from "./_permission.editor";
import { Form } from "components/placement/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "components/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { tryInvalidateApiCache } from "helpers/common";
import Card from "components/Card";
import Button from "components/placement/Button";
import { Link } from "react-router-dom";

/**
 * This component will fetch a menu item and display a list of all form permissions,
 * allowing the user to map the menu item to the form permission(s).
 */
const MenuItemForm = ({ menuItem }: { menuItem: MenuItemSchema }) => {
  const navigate = useNavigate();

  const {
    data: availablePerms,
    isLoading: isLoadingFormPermissions,
    isFetched: isFetchedFormPermissions,
    error: errorFormPermissions, // TODO: display error to user
  } = usePermissionGetByGroups({
    enabled: !!menuItem,
    groups: ["form", "api"],
    onError: () => {
      console.error("An error occurred while fetching permissions!");
      toast.error("An error occurred while fetching permissions!");
    },
  });

  const { mutate } = useMenuItemFormPermissionMutate({
    onSuccess: async () => {
      toast.success("Menu item form permissions updated successfully.");

      await tryInvalidateApiCache("/api/menus-get");

      navigate("./../../");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<MenuItemFormPermissionMutateSchema>({
    defaultValues: {
      id: menuItem.id,
      permissions: {} as MenuItemFormPermissionMutateSchema["permissions"],
    },
    resolver: zodResolver(menuItemFormPermissionMutateSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (availablePerms && menuItem) {
      const newFormData = {
        id: menuItem.id,
        permissions: availablePerms.reduce(
          (acc: Record<string, boolean>, ap: Permission) => {
            const formPerms = menuItem.formPermissions ?? [];
            const apiPerms = menuItem.apiPermissions ?? [];

            acc[ap.id] = [...formPerms, ...apiPerms].some(
              (mifp) => mifp.id === ap.id
            );
            return acc;
          },
          {}
        ),
      };

      resetForm(newFormData);
    }
  }, [availablePerms, isFetchedFormPermissions, menuItem, resetForm]);

  if (isLoadingFormPermissions) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  if (!menuItem) {
    return <Alert theme="danger">Menu item not found.</Alert>;
  }

  if (errorFormPermissions) {
    return (
      <Alert theme="danger">
        An error occurred while fetching menu item form permissions.
      </Alert>
    );
  }

  const onSubmit = async (data: MenuItemFormPermissionMutateSchema) => {
    await mutate(data);
  };

  const selectedPerms = reactForm.watch("permissions"); // Watch for changes in permissions

  const availFormPerms = availablePerms?.filter((p) => p.group === "form");
  const availApiPerms = availablePerms?.filter((p) => p.group === "api");

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <div className="flex justify-between px-4">
            <Button
              type="button"
              variant="default"
              size="sm"
              onClick={() => navigate("./../../")}
            >
              Cancel
            </Button>
            <div className="space-x-4">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </div>
          <Card>
            <div className="mb-4">
              <p className="">
                Selected form permissions will be mapped to this menu item for
                auto-toggling form permissions in the{" "}
                <Link
                  to="/admin/config/roles"
                  className="text-primary hover:text-primary-dark"
                >
                  role menu
                </Link>{" "}
                page.
              </p>
              <p className="text-xs text-info-500">
                Please note: this will not remove any existing form permissions
                from each role.
              </p>
            </div>
            <div className="flex flex-row items-start space-x-3">
              {!!availFormPerms && (
                <MenuItemFormPermissionEditor
                  key={`${menuItem.id}-form-permissions-editor`}
                  title={`Form Permissions`}
                  menuItem={menuItem}
                  availablePermissions={availFormPerms}
                  selectedPermissions={selectedPerms}
                  reactForm={reactForm}
                  editable={true}
                />
              )}
              {!!availApiPerms && (
                <MenuItemFormPermissionEditor
                  key={`${menuItem.id}-api-permissions-editor`}
                  title={`API Permissions`}
                  menuItem={menuItem}
                  availablePermissions={availApiPerms}
                  selectedPermissions={selectedPerms}
                  reactForm={reactForm}
                  editable={true}
                />
              )}
            </div>
          </Card>
        </form>
      </Form>
    </>
  );
};

export default MenuItemForm;
