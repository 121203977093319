import MenuItemForm from "components/config/menuitem/form";
import { Alert } from "components/core";
import PageHeader from "components/PageHeader";
import { Skeleton } from "components/ui/skeleton";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { useMenuItemGetOneById } from "hooks/useMenu";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ConfigMenuItemMutatePage = () => {
  const { id } = useParams();

  const {
    data: menuItem,
    isLoading: isLoadingMenuItem,
    isFetched: isFetchedMenuItem,
    error: errorMenuItem, // TODO: display error to user
  } = useMenuItemGetOneById({
    enabled: !isNullEmptyOrWhitespace(id),
    id: id ?? "",
    includePermissions: true,
    onError: () => {
      console.error("An error occurred while fetching menu item!");
      toast.error("An error occurred while fetching menu item!");
    },
  });

  if (isLoadingMenuItem || !isFetchedMenuItem) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  if (isNullEmptyOrWhitespace(menuItem)) {
    return <Alert theme="danger">Menu item not found.</Alert>;
  }

  if (errorMenuItem) {
    return (
      <Alert theme="danger">An error occurred while fetching menu item.</Alert>
    );
  }

  return (
    <>
      <PageHeader>
        <div className="text-xl">Edit Menu Item - {menuItem.title}</div>
      </PageHeader>
      <div className="col-span-full">
        <MenuItemForm menuItem={menuItem} />
      </div>
    </>
  );
};

export default ConfigMenuItemMutatePage;
