import { useMemo, useState } from "react";
import { Alert, Badge, List, ListItem } from "components/core";
import { FileAudio, FileVideo2 } from "lucide-react";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "components/Card";
import FormListFilter from "components/FormListFilter";
import classNames from "classnames";
import { convertHexToRGBA } from "helpers/stringUtilities";
// import MediaUploadForm from "components/forms/MediaUploadForm";
import ButtonNew from "components/Historical/ButtonNew";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Tabs from "components/Tabs";
import { IMediaItem } from "helpers/mediaUtilities";
import { useMediaGetMany } from "hooks/useMedia";
import { useFormGetMany } from "hooks/useForm";
import { PersistantFormValuesProvider } from "context/PersistantFormValuesProvider";

const limit = 20;

const ListPage = () => {
  const {
    isLoading: isLoadingForms,
    isFetched: isFetchedForms,
    data: forms,
  } = useFormGetMany();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [params, setParams] = useState<Record<string, string>>({}); // Search params
  const [isReady, setIsReady] = useState(false);

  const {
    isLoading: isLoadingMedia,
    error: errorMedia,
    data: mediaData,
    loadMore: loadMoreMedia,
    hasMore: hasMoreMedia,
  } = useMediaGetMany({
    enabled: isReady,
    params,
    limit,
    orderBy: "modifiedAt",
    orderByDirection: "desc",
  });

  const filteredForms = useMemo(() => {
    return forms?.filter((f) => f.ModuleName?.toLowerCase() === "media");
  }, [forms]);

  const filterForms = useMemo(() => {
    return filteredForms?.filter((f) =>
      f.FormName.toLowerCase().startsWith("filters_")
    );
  }, [filteredForms]);

  let view = searchParams.get("view") ?? undefined;
  if (view === undefined) {
    view = filterForms?.[0]?.FormName?.toLowerCase();
  }

  const activeFilterForm = useMemo(() => {
    return filterForms?.find((f) => f.FormName.toLowerCase() === view);
  }, [view, filterForms]);

  const handleClickListItem = (item: IMediaItem) => {
    // TODO: redirect to edit page
    const search = searchParams.toString();
    return navigate(`${item.formname}/${item.id}${search ? `?${search}` : ""}`);
  };

  function onChangeView(view: string) {
    setSearchParams({ view });
  }

  function handleButtonNewClick(formType: string, formName: string) {
    const search = searchParams.toString();
    return navigate(`${formName}/new${search ? `?${search}` : ""}`);
  }

  const handleSubmit = async (params: Record<string, string>) => {
    setParams((prevParams) => {
      // did they change?
      if (JSON.stringify(prevParams) === JSON.stringify(params)) {
        return prevParams;
      }

      return params;
    });
    setIsReady(true);
  };

  if (isLoadingForms || !isFetchedForms) {
    return <ListSkeleton />;
  }

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              Media Library
            </div>
            <ButtonNew
              className="justify-end"
              onClickOption={handleButtonNewClick}
              options={filteredForms
                .filter((f) => !f.FormName.toLowerCase().startsWith("filters_"))
                .map((f) => ({
                  FormName: f.FormName,
                  FormTitle: f.FormTitle,
                  FormType: f.FormType,
                }))}
            />
          </div>
        </div>

        <div className="col-span-full">
          <Tabs
            tabs={filterForms?.map((form) => ({
              id: form.FormName,
              name: form.FormTitle,
              current: view === form.FormName,
            }))}
            onChange={(id: string) => onChangeView(id)}
          />
        </div>

        {activeFilterForm !== undefined && (
          <div className="col-span-full relative">
            <PersistantFormValuesProvider>
              <FormListFilter
                key={activeFilterForm.FormName}
                form={activeFilterForm}
                onSubmit={handleSubmit}
                persistantFormValueKey={`${location.pathname}-view:${view}`}
              />
            </PersistantFormValuesProvider>
          </div>
        )}

        <div className="col-span-full">
          <Card>
            <div className="flex flex-col flex-grow space-y-4">
              {!isLoadingMedia && errorMedia ? (
                <Alert theme="danger">
                  An error occurred while loading the media library.
                </Alert>
              ) : !isLoadingMedia && !mediaData ? (
                <div className="flex flex-col items-center justify-center p-6">
                  <p className="text-xs text-center">
                    No records found. Try redefining your search params.
                  </p>
                </div>
              ) : (
                <InfiniteScroll
                  className="infinite-scroll"
                  style={{ overflow: "visible" }}
                  dataLength={mediaData.length}
                  next={loadMoreMedia}
                  hasMore={hasMoreMedia}
                  loader={<ListSkeleton />}
                  endMessage={
                    <p className="pt-4 text-xs text-center">End of results.</p>
                  }
                >
                  <List theme="striped">
                    {mediaData.map((media) => (
                      <ListItem
                        key={`doc-${media.id}`}
                        onClick={() => handleClickListItem(media)}
                      >
                        <div className="flex-shrink-0">
                          <FileTypeIcon
                            fileType={media.filetype}
                            className="h-12 w-12 text-gray-300"
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 items-center">
                          <div className="space-y-2">
                            <div className="text-sm font-medium text-primary truncate">
                              {media.filename}
                            </div>
                            <div className="text-xs text-gray-500 space-y-2">
                              {media.farmname ? (
                                <div>{media.farmname}</div>
                              ) : null}

                              {media.tagsdata?.length > 0 ? (
                                <div className="flex flex-auto flex-wrap">
                                  {media.tagsdata.map((tag) => {
                                    return (
                                      <Badge
                                        className="mr-1 mb-1"
                                        icon={undefined}
                                        iconPosition={undefined}
                                        style={{
                                          backgroundColor:
                                            tag?.Color !== undefined
                                              ? convertHexToRGBA(
                                                  tag.Color,
                                                  0.05
                                                )
                                              : null,
                                          color:
                                            tag?.Color !== undefined
                                              ? tag.Color
                                              : null,
                                          borderColor:
                                            tag?.Color !== undefined
                                              ? tag.Color
                                              : null,
                                        }}
                                      >
                                        {tag.Title}
                                      </Badge>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </InfiniteScroll>
              )}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
};

export default ListPage;

export function FileTypeIcon({
  fileType,
  className,
}: {
  fileType: IMediaItem["filetype"];
  className?: string;
}) {
  if (fileType.startsWith("image/")) {
    return (
      <svg
        className={classNames(className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 463 463"
        fill="currentColor"
        stroke="currentColor"
      >
        <path d="M439.5 50.581h-416c-12.958 0-23.5 10.542-23.5 23.5V388.92c0 12.958 10.542 23.5 23.5 23.5h416c12.958 0 23.5-10.542 23.5-23.5V74.081c0-12.958-10.542-23.5-23.5-23.5zm-416 15h416c4.687 0 8.5 3.813 8.5 8.5V310.71h-76.103L222.352 161.165c-2.929-2.929-7.678-2.929-10.606 0l-81.407 81.406-30.826-30.826c-2.929-2.929-7.678-2.929-10.606 0L15 285.651V74.081c0-4.687 3.813-8.5 8.5-8.5zm416 331.838h-416c-4.687 0-8.5-3.813-8.5-8.5v-82.055l79.209-79.209 30.823 30.823c.001.001.001.002.002.002s.001.001.002.002l36.127 36.127c2.929 2.929 7.678 2.929 10.606 0 2.929-2.929 2.929-7.678 0-10.606l-30.826-30.826 76.103-76.103 146.438 146.438c1.406 1.407 3.314 2.197 5.303 2.197H448v63.209c0 4.688-3.813 8.501-8.5 8.501z" />
        <path d="M354.338 195.645c20.073 0 36.403-16.331 36.403-36.403s-16.331-36.403-36.403-36.403-36.403 16.33-36.403 36.403 16.331 36.403 36.403 36.403zm0-57.806c11.802 0 21.403 9.601 21.403 21.403s-9.602 21.403-21.403 21.403-21.403-9.602-21.403-21.403 9.602-21.403 21.403-21.403z" />
      </svg>
    );
  } else if (fileType.startsWith("video/")) {
    return <FileVideo2 className={classNames(className)} />;
  } else if (fileType.startsWith("audio/")) {
    return <FileAudio className={classNames(className)} />;
  } else {
    return (
      <svg
        className={classNames(className)}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        // stroke="currentColor"
      >
        <path d="M20,6.52897986 L20,19.5010024 C20,20.8817143 18.8807119,22.0010024 17.5,22.0010024 L6.5,22.0010024 C5.11928813,22.0010024 4,20.8817143 4,19.5010024 L4,4.50100238 C4,3.1202905 5.11928813,2.00100238 6.5,2.00100238 L15.4720225,2.00100238 C15.6047688,1.99258291 15.7429463,2.03684187 15.8535534,2.14744899 L19.8535534,6.14744899 C19.9641605,6.25805611 20.0084195,6.39623363 20,6.52897986 Z M15,3.00100238 L6.5,3.00100238 C5.67157288,3.00100238 5,3.67257525 5,4.50100238 L5,19.5010024 C5,20.3294295 5.67157288,21.0010024 6.5,21.0010024 L17.5,21.0010024 C18.3284271,21.0010024 19,20.3294295 19,19.5010024 L19,7.00100238 L15.5,7.00100238 C15.2238576,7.00100238 15,6.77714475 15,6.50100238 L15,3.00100238 Z M16,3.70810916 L16,6.00100238 L18.2928932,6.00100238 L16,3.70810916 Z M12.2879737,11.9579134 L13.817339,15.0166439 C13.8765619,15.0057128 13.9376138,15 14,15 L15,15 C15.5522847,15 16,15.4477153 16,16 L16,17 C16,17.5522847 15.5522847,18 15,18 L14,18 C13.4477153,18 13,17.5522847 13,17 L13,16 L10,16 L10,17 C10,17.5522847 9.55228475,18 9,18 L8,18 C7.44771525,18 7,17.5522847 7,17 L7,16 C7,15.4477153 7.44771525,15 8,15 L9,15 C9.06238619,15 9.12343806,15.0057128 9.18266103,15.0166439 L10.7120263,11.9579134 C10.3001179,11.8342569 10,11.4521766 10,11 L10,10 C10,9.44771525 10.4477153,9 11,9 L12,9 C12.5522847,9 13,9.44771525 13,10 L13,11 C13,11.4521766 12.6998821,11.8342569 12.2879737,11.9579134 L12.2879737,11.9579134 Z M11,10 L11,11 L12,11 L12,10 L11,10 Z M8,16 L8,17 L9,17 L9,16 L8,16 Z M14,16 L14,17 L15,17 L15,16 L14,16 Z M11.5,12.618034 L10.309017,15 L12.690983,15 L11.5,12.618034 Z" />
      </svg>
    );
  }
}
