import Card from "components/Card";
import { Skeleton } from "components/ui/skeleton";
import { isRequiredPermission, tryInvalidateApiCache } from "helpers/common";
import { useRoleDelete, useRoleGetAll } from "hooks/useRole";
import { type UserMeSchema } from "hooks/useUser";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const RoleList = ({ user }: { user: UserMeSchema }) => {
  const {
    data: roles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useRoleGetAll();

  const { mutate: deleteRoleMutate } = useRoleDelete({
    onSuccess: async () => {
      toast.success("Role deleted successfully.");

      await tryInvalidateApiCache("/api/roles-get");
      refetchRoles();
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  if (isLoadingRoles) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  const handleClickDeleteRole = (id: string) => {
    if (window.confirm("Are you sure you want to delete this role?")) {
      deleteRoleMutate(id);
    }
  };

  return (
    <Card>
      <div className="">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-semibold py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 tablet:pl-0"
              >
                Name
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!!roles ? (
              roles.map((role) => {
                const isSysAdmin =
                  role.name.toLowerCase() === "system administrator";
                return (
                  <tr key={role.id}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                      {!isSysAdmin && (
                        <Link to={`${role.id}`}>{role.name}</Link>
                      )}
                      {isSysAdmin && role.name}
                      <dl className="text-xs text-gray-500">
                        <dt className="sr-only">Description</dt>
                        <dd className="mt-1 truncate">{role.description}</dd>
                      </dl>
                      {!isSysAdmin && (
                        <div className="text-xs inline-flex space-x-2 divide-x">
                          <Link
                            to={`${role.id}`}
                            className="text-primary hover:text-primary-dark"
                          >
                            Edit
                          </Link>
                          <Link
                            to={`${role.id}/menus`}
                            className="text-primary hover:text-primary-dark pl-2"
                          >
                            Menu Permissions
                          </Link>
                          {isRequiredPermission(
                            user?.permissions,
                            "rolepermissions:write",
                            "api"
                          ) && (
                            <>
                              <Link
                                to={`${role.id}/permissions`}
                                className="text-primary hover:text-primary-dark pl-2"
                              >
                                All Permissions
                              </Link>
                              <Link
                                to="#"
                                className="text-primary hover:text-primary-dark pl-2"
                                onClick={() => handleClickDeleteRole(role.id)}
                              >
                                Delete
                              </Link>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="">
                  No roles found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default RoleList;
