import { db } from "db";
import {
  FORM_DATA_QUEUE_STATUS,
  type DBFormDataQueue,
  deserialiseFormData,
} from "db/FormDataQueue";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useFetch from "hooks/useFetch";
import {
  tryInvalidateFormDataCache,
  useSyncFormDataGetMany,
} from "hooks/useFormData";
import { DateTime } from "luxon";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Id as ToastId, toast } from "react-toastify";

export const MAX_SEND_ATTEMPTS = 5;

interface syncDataContext {
  failedCount: number;
  pendingCount: number;
  queue: DBFormDataQueue[] | undefined;
  isSyncing: boolean;
  sync: () => Promise<void>;
}

const SyncDataContext = createContext({} as syncDataContext);

function getSendStatus(error: string, sendAttempts: number) {
  return !isNullEmptyOrWhitespace(error)
    ? sendAttempts < MAX_SEND_ATTEMPTS
      ? FORM_DATA_QUEUE_STATUS.PENDING
      : FORM_DATA_QUEUE_STATUS.FAILED
    : FORM_DATA_QUEUE_STATUS.SENT;
}

const SyncDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: queue } = useSyncFormDataGetMany();

  const [failedCount, setFailedCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);

  const toastId = useRef<ToastId>("");
  const isCancelled = useRef(false);
  const isSyncingRef = useRef(false);

  const { execute } = useFetch({
    onSuccess: (response, data) => {
      // TODO: display individual success/failed requests
      // toast.success(`Successfully synced`)
    },
    onError: (errMessage) => {
      // toast.error(errMessage);
    },
  });

  const syncFormData = useCallback(async () => {
    // Clear expired sent form data
    await clearExpiredSentFormData();

    toastId.current = toast.info(`Starting form data sync...`, {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
    });

    if (!navigator.onLine) {
      toast.update(toastId.current, {
        render:
          "You are currently offline. Data syncing is not available offline.",
        type: toast.TYPE.ERROR,
      });

      setIsSyncing(false);
      isSyncingRef.current = true;

      return;
    }

    if (isSyncingRef.current) {
      toast.update(toastId.current, {
        render: "Sync already in progress.",
        type: toast.TYPE.INFO,
      });

      setIsSyncing(false);

      return;
    }

    setIsSyncing(true);
    isSyncingRef.current = true;

    try {
      const queuedFormData = await db.formdataqueue
        .where("sendStatus")
        .equals(FORM_DATA_QUEUE_STATUS.PENDING)
        .toArray();

      if (!queuedFormData.length) {
        toast.update(toastId.current, {
          render: "All data successfully synced.",
          type: toast.TYPE.INFO,
          closeButton: true,
          closeOnClick: true,
          autoClose: 3000,
        });

        return;
      }

      // iterate through queuedFormData and send to server
      let index = 0;
      let failedCount = 0;
      for (const fd of queuedFormData) {
        if (!navigator.onLine) break; // Check if we are still online (in case we go offline during sync)
        if (isCancelled.current) break; // Check if the operation should be aborted
        index++;
        const sendAttempts = fd.sendAttempts + 1;

        toast.update(toastId.current, {
          render: (
            <div>
              <div>
                <p>
                  Syncing {index} of {queuedFormData.length} submission(s)
                </p>
                <p className="text-xs">
                  Do not refresh or close the app until complete.
                </p>
              </div>
            </div>
          ),
          type: toast.TYPE.INFO,
        });

        try {
          const formData = await deserialiseFormData(fd.data);
          const { error } = await execute(
            "POST",
            "/api/formvalues-post",
            formData
          );

          /* handle successful & failed requests, ignore requests that are not ready */
          const sendStatus = getSendStatus(error, sendAttempts);

          await db.formdataqueue.update(fd.id, {
            sendAttempts: sendAttempts,
            sendStatus,
          });

          if (!isNullEmptyOrWhitespace(error)) {
            failedCount++;
          }

          if (sendStatus === FORM_DATA_QUEUE_STATUS.SENT) {
            // invalidate the form data cache
            await tryInvalidateFormDataCache(
              `${window.location.origin}/api/formvaluesbytype-get?formType=${fd.formType}&farmId=${fd.farmCode}&moduleId=${fd.moduleId}`
            );
          }

          // if (sendStatus === FORM_DATA_QUEUE_STATUS.FAILED) {
          //   failedCount++;
          //   toast.update(toastId.current, {
          //     render: <div>
          //       <div>Syncing {index} of {queuedFormData.length} record(s)</div>
          //       <div>{failedCount} record(s) failed to sync. It will be retried {maxSendAttemps - sendAttempts} more time(s).</div>
          //     </div>,
          //     type: toast.TYPE.ERROR,
          //   });
          // }
        } catch (error) {
          console.error("Error syncing form data:", error);
          // Handle error or implement retry logic here

          /* handle successful & failed requests, ignore requests that are not ready */
          const sendStatus = getSendStatus(error as string, sendAttempts);

          await db.formdataqueue.update(fd.id, {
            sendAttempts: sendAttempts,
            sendStatus,
          });

          failedCount++;
        }
      }

      toast.update(toastId.current, {
        render: (
          <div>
            <div>
              {queuedFormData.length - failedCount} of {queuedFormData.length}{" "}
              submission(s) synced.
            </div>
            {failedCount ? (
              <div className="text-sm">
                <p>
                  {failedCount}{" "}
                  {failedCount === 1 ? "submission" : "submissions"} failed to
                  sync.
                </p>
              </div>
            ) : null}
          </div>
        ),
        type: failedCount ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
        autoClose: failedCount ? 10000 : 3000,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSyncing(false);
      isSyncingRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, setIsSyncing]);

  const MAX_DAYS_TO_KEEP_SENT_FORM_DATA = 30;
  const clearExpiredSentFormData = useCallback(async () => {
    const expiredDate = DateTime.local()
      .minus({ days: MAX_DAYS_TO_KEEP_SENT_FORM_DATA })
      .toMillis();

    // console.log("expiredDate", DateTime.local().toISODate(), DateTime.fromMillis(expiredDate).toISODate(), expiredDate);

    await db.formdataqueue
      .where("sendStatus")
      .equals(FORM_DATA_QUEUE_STATUS.SENT)
      .and((fd) => fd.lastModified < expiredDate)
      .delete();
  }, []);

  // Count failed and pending submissions
  useEffect(() => {
    if (!queue) {
      return;
    }

    let failedCount = 0;
    let pendingCount = 0;

    for (const submission of queue) {
      if (submission.sendStatus === FORM_DATA_QUEUE_STATUS.FAILED) {
        failedCount++;
      } else if (submission.sendStatus === FORM_DATA_QUEUE_STATUS.PENDING) {
        pendingCount++;
      }
    }

    setFailedCount(failedCount);
    setPendingCount(pendingCount);
  }, [queue]);

  // Sync data when user comes online
  useEffect(() => {
    window.addEventListener("online", syncFormData);

    return () => {
      window.removeEventListener("online", syncFormData);
    };
  }, [isSyncing, syncFormData]);

  useEffect(() => {
    const cleanup = () => {
      isCancelled.current = true;
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  // Sync on mount
  // Disabled for now to allow users to manually sync
  // Uncomment to enable auto sync on mount
  // Important: this will cause a sync to occur on every page load, be sure
  // not to include this hook on multiple pages/components in a single route.
  // useEffect(() => {
  //   if (navigator.onLine) {
  //     // If we are online, sync immediately with delay
  //     if (debounceTimer.current) clearTimeout(debounceTimer.current);
  //     debounceTimer.current = setTimeout(() => {
  //       syncFormData();
  //     }, SYNC_DELAY);
  //   }

  //   window.addEventListener("online", syncFormData);

  //   return () => {
  //     window.removeEventListener("online", syncFormData);
  //   };
  // }, [syncFormData]);

  const contextValue = useMemo(
    () => ({ queue, failedCount, pendingCount, isSyncing, sync: syncFormData }),
    [queue, failedCount, pendingCount, isSyncing, syncFormData]
  );

  return (
    <SyncDataContext.Provider value={contextValue}>
      {children}
    </SyncDataContext.Provider>
  );
};

export { SyncDataProvider as default, SyncDataContext };
