import RolePermissionForm from "components/config/role/permission/form";
import { Alert } from "components/core";
import PageHeader from "components/PageHeader";
import {
  invariantResponse,
  isNullEmptyOrWhitespace,
  isRequiredPermission,
} from "helpers/common";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router-dom";

const ConfigRolePermissionMutatePage = () => {
  const { roleId, id } = useParams();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "rolepermissions:write", "api"),
    "User is not authorized to view this page",
    404
  );

  if (isNullEmptyOrWhitespace(roleId)) {
    return <Alert theme="danger">Role ID is required.</Alert>;
  }

  const isNewPermission = isNullEmptyOrWhitespace(id);

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">
            {isNewPermission ? "Create" : "Edit"} Permission
          </div>
        </div>
      </PageHeader>
      <div className="col-span-full">
        <RolePermissionForm roleId={roleId} permissionId={id} />
      </div>
    </>
  );
};

export default ConfigRolePermissionMutatePage;
