import MenuList from "components/config/role/menu/list";
import PageHeader from "components/PageHeader";
import { invariantResponse, isRequiredPermission } from "helpers/common";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router-dom";

const ConfigRoleMenuPage = () => {
  const { roleId } = useParams();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "menu:write", "api"),
    "User is not authorized to view this page",
    404
  );

  invariantResponse(roleId, "Role ID is required.", 404);

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Role Menus</div>
        </div>
      </PageHeader>
      <div className="pt-4">
        <MenuList />
      </div>
    </>
  );
};

export default ConfigRoleMenuPage;
