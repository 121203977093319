import React, { useState, useEffect, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import { Label } from "components/core";
import Addon from "./Addon";
import { AlertCircleIcon } from "lucide-react";
import { SelectOption } from "./SelectOption";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { padNumber } from "helpers/dateUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

function formatValue(value) {
  if (isNullEmptyOrWhitespace(value)) return value;

  const timeSplit = value.split(":");
  const hour = padNumber(timeSplit[0]);
  const minute = padNumber(timeSplit[1]);

  return `${hour}:${minute}`;
}

const TimePicker12hr = ({
  label,
  id,
  hint,
  validate,
  value,
  setValue,
  setValid,
  required = true,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  addonLeft,
  addonRight,
  dependencies = [],
  theme,
  defaultValue,
  render,
  minuteStep = 5,
  labelSize,
  disableCalcTrigger,
  ...other
}) => {
  useCalcTrigger(formatValue(value), setValue, disableCalcTrigger);

  const hasDefaultValueRef = useRef(false);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [focused, setFocused] = useState(false);

  const padNumber = (num, length = 2) => num.toString().padStart(length, "0");

  const { hours12hrPadded, minutes, minutesPadded, ampm } =
    convert24hrTo12hr(value);
  // const [selectedAMPM, setSelectedAMPM] = useState(ampm);

  const hasValue = value?.length > 0;

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md pt-3 pb-3",
    "border",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-10");
  }

  // Theme
  if (theme === "darkgray") {
    inputClasses.push(
      "bg-gray-900 border-gray-500 text-gray-400 focus:border-gray-400 focus:ring-gray-800"
    );
    errorIconClasses.push("bg-gray-900");
  } else {
    inputClasses.push(
      "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
    );
    errorIconClasses.push("bg-white");
  }

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  /**
   * Validate the input value.
   * @returns {string|null} The error message or null if valid.
   */
  const validation = () => {
    if (validate) return validate(value);
  };

  /**
   * Handle change hours
   * @param {string|number} _hours
   */
  const handleChangeHours = (_hours) => {
    const newValue = convert12hrTo24hr(_hours, minutes, ampm);
    setInputValue(newValue);
  };

  /**
   * Handle change minutes
   * @param {string|number} _minutes
   */
  const handleChangeMinutes = (_minutes) => {
    const newValue = convert12hrTo24hr(hours12hrPadded, _minutes, ampm);
    setInputValue(newValue);
  };

  /**
   * Handle change AM/PM
   * @param {string} _ampm
   */
  const handleChangeAMPM = (_ampm) => {
    const newValue = convert12hrTo24hr(hours12hrPadded, minutesPadded, _ampm);

    setInputValue(newValue);
    // setSelectedAMPM(_ampm);
  };

  //#endregion

  //#region Side-effects

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (
      touched ||
      !defaultValue ||
      !setInputValue ||
      hasDefaultValueRef.current
    )
      return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
      hasDefaultValueRef.current = true;
    }
  }, [value, defaultValue, setInputValue, touched]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      <Label
        id={id}
        text={label}
        required={required}
        focused={focused}
        hasValue={hasValue}
        position={labelPosition}
        theme={theme}
        erroneous={touched && error && !focused}
        size={labelSize}
      />
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div
          className={`block w-full tablet:text-sm relative ${inputClasses.join(
            " "
          )} ${
            touched && error && !focused ? inputErrorClasses.join(" ") : ""
          }`}
        >
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          <div className="inline-flex items-center">
            <select
              data-cy="input-hour"
              id={id}
              className={`block w-full tablet:text-sm border-0 mx-0 pr-4 py-0 appearance-none bg-none bg-transparent focus:border-0 focus:outline-none focus:ring-0 cursor-pointer`}
              value={hours12hrPadded}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => handleChangeHours(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              {isNullEmptyOrWhitespace(hours12hrPadded) && (
                <SelectOption>--</SelectOption>
              )}
              {Array.from({ length: 12 }, (_, i) => i + 1).map((h) => (
                <SelectOption key={`option-${h}`} value={padNumber(h, 2)}>
                  {padNumber(h, 2)}
                </SelectOption>
              ))}
            </select>
            <span className="">:</span>
            <select
              data-cy="input-minute"
              id={id}
              className={`block w-full tablet:text-sm border-0 mx-0 pr-4 py-0 appearance-none bg-none bg-transparent focus:border-0 focus:outline-none focus:ring-0 cursor-pointer`}
              value={minutesPadded}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => handleChangeMinutes(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              {isNullEmptyOrWhitespace(minutes) && (
                <SelectOption>--</SelectOption>
              )}
              {Array.from(
                { length: 60 / minuteStep },
                (_, i) => i * minuteStep
              ).map((m) => (
                <SelectOption key={`option-${m}`} value={padNumber(m, 2)}>
                  {padNumber(m, 2)}
                </SelectOption>
              ))}
            </select>
            {/* Am/PM select */}
            <select
              data-cy="input-ampm"
              id={id}
              className={`block w-full min-w-fit tablet:text-sm border-0 mx-0 pr-4 py-0 appearance-none bg-none bg-transparent focus:border-0 focus:outline-none focus:ring-0 cursor-pointer`}
              value={ampm}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => handleChangeAMPM(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              <SelectOption value="AM">AM</SelectOption>
              <SelectOption value="PM">PM</SelectOption>
            </select>
          </div>
          {addonRight && <Addon position="right">{addonRight}</Addon>}
          {touched && error && !focused && (
            <div className="absolute -top-2 right-2 pointer-events-none bg-white px-1">
              <AlertCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

TimePicker12hr.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hint: PropTypes.string,
  validate: PropTypes.func,
  type: PropTypes.oneOf([
    "text",
    "email",
    "password",
    "number",
    "url",
    "date",
    "datetime-local",
    "month",
    "week",
    "time",
    "search",
    "tel",
    "checkbox",
    "radio",
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  setValid: PropTypes.func,
  labelPosition: PropTypes.string,
  addonLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  addonRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dependencies: PropTypes.array,
  render: PropTypes.bool,
  minuteStep: PropTypes.number,
};

export { TimePicker12hr };

function convert24hrTo12hr(timeString) {
  // Let's set some defaults
  if (typeof timeString !== "string")
    return {
      hours24hr: null,
      hours12hrPadded: null,
      minutes: null,
      minutesPadded: null,
      ampm: "AM",
    };

  const [hourStr, minuteStr] = timeString.split(":");
  if (!hourStr || !minuteStr)
    return {
      hours24hr: null,
      hours12hrPadded: null,
      minutes: null,
      minutesPadded: null,
      ampm: "AM",
    };

  const hours = Number(hourStr);
  const minutes = Number(minuteStr);

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return null;
  }

  // make the conversions
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12hr = padNumber(hours % 12 || 12);
  const minutesPadded = padNumber(minutes);

  return {
    hours24hr: hours,
    hours12hrPadded: hours12hr,
    minutes,
    minutesPadded,
    ampm,
  };
}

function convert12hrTo24hr(hours12hr, minutes, ampm) {
  // Let's set some defaults
  hours12hr = hours12hr ?? "12";
  minutes = minutes ?? "00";
  ampm = ampm ?? "AM";

  const hours = Number(hours12hr);
  const minutesNum = Number(minutes);

  if (
    isNaN(hours) ||
    isNaN(minutesNum) ||
    hours < 1 ||
    hours > 12 ||
    minutesNum < 0 ||
    minutesNum > 59
  ) {
    return "00:00";
  }

  // make the conversions
  let hours24hr;
  if (ampm.toUpperCase() === "PM") {
    hours24hr = hours === 12 ? 12 : hours + 12; // handle PM (12:xx PM is 12:xx)
  } else if (ampm.toUpperCase() === "AM") {
    hours24hr = hours === 12 ? 0 : hours; // handle AM (12:xx AM is 00:xx)
  } else {
    return "00:00"; // invalid AM/PM input
  }

  return `${padNumber(hours24hr)}:${padNumber(minutesNum)}`;
}