import { authConfig } from "authConfig";
import useFetch from "./useFetch";
import React from "react";
import {
  onErrorRequest,
  onErrorUpdateMutation,
  onSuccessfulMutation,
  onSuccessfulRequest,
} from "types";
import { z } from "zod";
import { toast } from "react-toastify";

export const roleSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
});

export const permissionSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  group: z.string(),
});

export const rolePermissionsSchema = roleSchema.extend({
  permissions: z.array(permissionSchema),
  // legacyPermLevel: z.string().optional(),
  farmAccessType: z.string().optional(),
  language: z.string().optional(),
});

export type Role = z.infer<typeof roleSchema>;
export type Permission = z.infer<typeof permissionSchema>;
export type RoleWithPermissions = z.infer<typeof rolePermissionsSchema>;

type useRoleGetAllProps = {
  enabled?: boolean;
};
export const useRoleGetAll = ({ enabled = true }: useRoleGetAllProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch roles");
    },
  });

  const [data, setData] = React.useState<Role[]>([]);

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", "/api/roles-get");

    const roles = data?.d ?? [];
    const result = z.array(roleSchema).safeParse(roles);
    if (!result.success) {
      console.error("Error parsing roles", result.error);
      return;
    }

    setData(result.data);

    return result.data;
  }, [execute]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

export const useRoleGetOne = ({
  enabled = true,
  id,
  onSuccess,
  onError,
}: {
  enabled?: boolean;
  id: string;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<
    RoleWithPermissions | undefined | null
  >(undefined);

  const fetchData = React.useCallback(async () => {
    const { data, error } = await execute("GET", `/api/role-get?id=${id}`);
    if (authConfig.mode === "b2c" && error === "request not ready") {
      // this is expected behavior for B2C
      setData(undefined);
      return;
    }

    const role = data?.d ?? null;
    const result = rolePermissionsSchema.safeParse(role);
    if (!result.success) {
      console.error("Error parsing role", result.error);
      onError?.("Error parsing role");
      setData(null);
      return;
    }

    setData(result.data);

    return result.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, id]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

export const roleMutateSchema = z.object({
  id: z.string().uuid().optional(),
  name: z.string().min(1, "Name is required.").max(255),
  description: z.string(),
  // legacyPermLevel: z.number(),
  farmAccessType: z.string().min(1, "Farm Access Type is required.").max(255),
  language: z.string().min(1, "Language is required.").max(255),
});
export type RoleMutateSchema = z.infer<typeof roleMutateSchema>;
type useRoleCreateProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useRoleMutate = ({
  onSuccess,
  onError,
}: useRoleCreateProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (formdata: RoleMutateSchema, variables?: any) => {
      const { data } = await execute(
        "POST",
        "/api/role-post",
        formdata,
        variables
      );

      return data?.d ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

export const roleDeleteSchema = z.object({
  id: z.string(),
});
export type RoleDeleteSchema = z.infer<typeof roleDeleteSchema>;
type useRoleDeleteProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useRoleDelete = ({
  onSuccess,
  onError,
}: useRoleDeleteProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (id: string, variables?: any) => {
      const { data } = await execute("DELETE", `/api/role-delete`, {
        id,
      });

      return data?.d ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

export const rolePermissionSchema = z.record(z.string().uuid(), z.boolean());
export const rolePermissionsMutateSchema = z.object({
  id: z.string().uuid().optional(),
  // e.g. [{ permissionId1: true }, { permissionId2: true }]
  permissions: rolePermissionSchema,
});
export type RolePermissionsMutateSchema = z.infer<
  typeof rolePermissionsMutateSchema
>;
type useRolePermissionsProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useRolePermissionsMutate = ({
  onSuccess,
  onError,
}: useRolePermissionsProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (
      formdata: z.infer<typeof rolePermissionsMutateSchema>,
      variables?: any
    ) => {
      const { data } = await execute(
        "POST",
        "/api/role-permissions-post",
        formdata,
        variables
      );

      return data?.d ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type usePermissionGetAllProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
export const usePermissionGetAll = ({
  enabled = true,
  onSuccess,
  onError,
}: usePermissionGetAllProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<Permission[]>([]);

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", "/api/permissions-get");

    const permissions = data?.d ?? [];
    const result = z.array(permissionSchema).safeParse(permissions);
    if (!result.success) {
      console.error("Error parsing roles", result.error);
      return;
    }

    setData(result.data);

    return result.data;
  }, [execute]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type usePermissionGetByGroupsProps = {
  enabled?: boolean;
  groups: string | string[];
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
export const usePermissionGetByGroups = ({
  enabled = true,
  groups,
  onSuccess,
  onError,
}: usePermissionGetByGroupsProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<Permission[] | undefined | null>(
    undefined
  );

  const groupsCSV = Array.isArray(groups)
    ? groups.map((g) => g.trim()).join(",")
    : groups.trim();
  const fetchData = React.useCallback(async () => {
    const { data, error } = await execute(
      "GET",
      `/api/permissions-by-groups-get?groups=${groupsCSV}`
    );
    if (authConfig.mode === "b2c" && error === "request not ready") {
      // this is expected behavior for B2C
      setData(undefined);
      return;
    }

    const permissions = data?.d ?? null;
    const result = z.array(permissionSchema).safeParse(permissions);
    if (!result.success) {
      console.error("Error parsing permission groups", result.error);
      onError?.("Error parsing permission groups");
      setData(null);
      return;
    }

    setData(result.data);

    return result.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, groupsCSV]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type usePermissionGetOneProps = {
  enabled?: boolean;
  id: string;
};
export const usePermissionGetOne = ({
  enabled = true,
  id,
}: usePermissionGetOneProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch permission");
    },
  });

  const [data, setData] = React.useState<Permission | undefined | null>(
    undefined
  );

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", `/api/permission-get?id=${id}`);
    if (authConfig.mode === "b2c" && error === "request not ready") {
      // this is expected behavior for B2C
      setData(undefined);
      return;
    }

    const permission = data?.d ?? null;
    const result = permissionSchema.safeParse(permission);
    if (!result.success) {
      console.error("Error parsing permission", result.error);
      setData(null);
      return;
    }

    setData(result.data);

    return result.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, id]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

export const rolePermissionMutateSchema = z.object({
  roleId: z
    .string({
      required_error: "Role ID is required.",
    })
    .uuid(),
  id: z
    .string({
      required_error: "Permission ID is required.",
    })
    .uuid()
    .optional(),
  name: z.string().min(1, "Name is required.").max(255),
  group: z.string().min(1, "Group is required.").max(255),
});
export type RolePermissionMutateSchema = z.infer<
  typeof rolePermissionMutateSchema
>;
type useRolePermissionMutateProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useRolePermissionMutate = ({
  onSuccess,
  onError,
}: useRolePermissionMutateProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (
      formdata: z.infer<typeof rolePermissionMutateSchema>,
      variables?: any
    ) => {
      const { data } = await execute(
        "POST",
        "/api/role-permission-post",
        formdata,
        variables
      );

      return data?.d ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

export const rolePermissionDeleteSchema = z.object({
  id: z.string(),
});
export type RolePermissionDeleteSchema = z.infer<
  typeof rolePermissionDeleteSchema
>;
type useRolePermissionDeleteProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useRolePermissionDelete = ({
  onSuccess,
  onError,
}: useRolePermissionDeleteProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = React.useCallback(
    async (id: string, variables?: any) => {
      const { data } = await execute("DELETE", `/api/role-permission-delete`, {
        id,
      });

      return data?.d ?? null;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

export const roleFormSchema = z.object({
  legacyPermLevelOptions: z.array(
    z.object({ value: z.number(), text: z.string() })
  ),
  legacyPermGroupOptions: z.array(
    z.object({ value: z.number(), text: z.string() })
  ),
  languagesOptions: z.array(z.object({ value: z.string(), text: z.string() })),
  farmAccessOptions: z.array(z.object({ value: z.string(), text: z.string() })),
});
export type RoleFormSchema = z.infer<typeof roleFormSchema>;
type useRoleFormProps = {
  enabled?: boolean;
};
export const useRoleForm = ({ enabled = true }: useRoleFormProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch role form");
    },
  });

  const [data, setData] = React.useState<RoleFormSchema | undefined | null>(
    undefined
  );

  const fetchData = React.useCallback(async () => {
    const { data, error } = await execute("GET", `/api/role-form-get`);
    if (authConfig.mode === "b2c" && error === "request not ready") {
      // this is expected behavior for B2C
      setData(undefined);
      return;
    }

    const role = data?.d ?? null;
    const result = roleFormSchema.safeParse(role);
    if (!result.success) {
      console.error("Error parsing role", result.error);
      setData(null);
      return;
    }

    setData(result.data);

    return result.data;
  }, [execute]);

  React.useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};
