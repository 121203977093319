import UserList from "components/config/user/list";
import { Button } from "components/core";
import PageHeader from "components/PageHeader";
import { useNavigate } from "react-router";
import { useUser } from "hooks/useUser";
import { PlusCircleIcon } from "lucide-react";
import { invariantResponse, isRequiredPermission } from "helpers/common";

const UserPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  // TODO: implement new error boundary mechanism
  // We shouldn't need to check if the user is signed in here
  // but because of MSALs delayed response, we need to allow the page to render first
  // see: UserProvider.tsx LoadingOrError handler for how to handle this
  invariantResponse(
    isRequiredPermission(user?.permissions, "config:admin:users", "page"),
    "User is not authorized to view this page",
    404
  );

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">User(s)</div>
          {isRequiredPermission(user?.permissions, "user:write", "api") && (
            <div className="">
              <Button
                type="button"
                theme="primary"
                onClick={() => void navigate("new")}
              >
                <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
              </Button>
            </div>
          )}
        </div>
      </PageHeader>
      <div className="col-span-full">
        <UserList />
      </div>
    </>
  );
};

export default UserPage;
