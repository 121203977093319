import {
  onErrorRequest,
  onErrorUpdateMutation,
  onSuccessfulMutation,
  onSuccessfulRequest,
} from "types";
import useFetch from "./useFetch";
import { formSchema } from "components/dynamic_form/form_schema";
import React, { useEffect } from "react";
import z from "zod";
import { isNullEmptyOrWhitespace, tryInvalidateApiCache } from "helpers/common";
import {
  DynamicFormBuilderDef,
  DynamicFormListDef,
} from "components/dynamic_form/builder/types";
import {
  builderSchema,
  listSchema,
} from "components/dynamic_form/builder/schema";

//#region Builder

type useDynamicFormBuilderGetOneProps = {
  enabled?: boolean;
  id: string;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormBuilderGetOne = ({
  enabled = true,
  id,
  onSuccess,
  onError,
}: useDynamicFormBuilderGetOneProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<z.infer<typeof builderSchema> | null>(
    null
  );

  const fetchData = React.useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/dynamic-form-builder-get?id=${id}`
    );

    const validatedData = builderSchema.safeParse(data?.d);
    if (!validatedData.success) {
      console.error(validatedData.error.errors, data);
      onError?.(
        `Error fetching dynamic form definitions: ${validatedData.error.message}`
      );
      return null;
    }

    setData(validatedData.data);

    return validatedData.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormBuilderGetManyProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormBuilderGetMany = ({
  enabled = true,
  onSuccess,
  onError,
}: useDynamicFormBuilderGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<DynamicFormListDef | null>(null);

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", `/api/dynamic-forms-builder-get`);

    const validatedData = listSchema.safeParse(data?.d ?? []);
    if (!validatedData.success) {
      console.error(validatedData.error.errors, data);
      onError?.(
        `Error fetching dynamic form definitions: ${validatedData.error.message}`
      );
      return null;
    }

    setData(validatedData.data);

    return validatedData.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormSaveProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
const useDynamicFormBuilderSave = ({
  onSuccess,
  onError,
}: useDynamicFormSaveProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: async () => {
      await tryInvalidateApiCache("/api/dynamic-forms-get");
      onSuccess?.();
    },
    onError,
  });

  const mutate = React.useCallback(
    async (form: DynamicFormBuilderDef) => {
      const { data } = await execute("POST", "/api/dynamic-form-post", form);

      return data;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useDynamicFormDeleteProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
const useDynamicFormBuilderDelete = ({
  onSuccess,
  onError,
}: useDynamicFormDeleteProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: async () => {
      await tryInvalidateApiCache("/api/dynamic-forms-get");
      onSuccess?.();
    },
    onError,
  });

  const mutate = React.useCallback(
    async (formId: string) => {
      const { data } = await execute(
        "DELETE",
        `/api/dynamic-form-delete?id=${formId}`
      );

      return data;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

//#endregion

//#region Form
type useDynamicFormGetOneProps = {
  enabled?: boolean;
  id: string;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormGetOne = ({
  enabled = true,
  id,
  onSuccess,
  onError,
}: useDynamicFormGetOneProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<z.infer<typeof formSchema> | null>(
    null
  );

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", `/api/dynamic-form-get?id=${id}`);

    const validatedData = formSchema.safeParse(data?.d);
    if (!validatedData.success) {
      console.error(validatedData.error.errors, data);
      onError?.(
        `Error fetching dynamic form definitions: ${validatedData.error.message}`
      );
      return null;
    }

    setData(validatedData.data);

    return validatedData.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormGetManyProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormGetMany = ({
  enabled = true,
  onSuccess,
  onError,
}: useDynamicFormGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<z.infer<typeof formSchema>[] | null>(
    null
  );

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", `/api/dynamic-forms-get`);

    const validatedData = z.array(formSchema).safeParse(data?.d ?? []);
    if (!validatedData.success) {
      console.error(validatedData.error.errors, data);
      onError?.(
        `Error fetching dynamic form definitions: ${validatedData.error.message}`
      );
      return null;
    }

    setData(validatedData.data);

    return validatedData.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormDataGetOneProps = {
  enabled?: boolean;
  id: string;
  formId: string;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormDataGetOne = ({
  enabled = true,
  id,
  formId,
  onSuccess,
  onError,
}: useDynamicFormDataGetOneProps) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<Record<string, any> | null>(null);

  const fetchData = React.useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/dynamic-form-data-get?formid=${formId}&id=${id}`
    );

    setData(data?.d);

    return data?.d;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormDataGetManyProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
const useDynamicFormDataGetMany = ({
  enabled = true,
  onSuccess,
  onError,
}: useDynamicFormDataGetManyProps = {}) => {
  const { isLoading, isFetched, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = React.useState<Record<string, any>[] | null>(null);

  const fetchData = React.useCallback(async () => {
    const { data } = await execute("GET", `/api/dynamic-form-datas-get`);

    setData(data?.d);

    return data?.d;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled && !isFetched) {
      fetchData();
    }
  }, [enabled, fetchData, isFetched]);

  return { isLoading, isFetched, error, data, refetch: fetchData };
};

type useDynamicFormDataSaveProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
const useDynamicFormDataSave = ({
  onSuccess,
  onError,
}: useDynamicFormDataSaveProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess: async () => {
      await tryInvalidateApiCache("/api/dynamic-form-data-get");
      onSuccess?.();
    },
    onError,
  });

  /**
   * Mutate function to save dynamic form data
   * @param data - The data to save
   * @param variables - Additional variables to pass to the API
   * @param varaibles.formId - The form ID
   * @param variables.id - The data ID - if provided, the API will update the data, otherwise it will create a new one
   */
  const mutate = React.useCallback(
    async (data: Record<string, any>, variables?: any) => {
      if (isNullEmptyOrWhitespace(variables?.formId)) {
        throw new Error("formId is required to save dynamic form data");
      }

      const queryUrl = variables?.id
        ? `/api/dynamic-form-data-post?id=${variables.id}&formId=${variables.formId}`
        : `/api/dynamic-form-data-post?formId=${variables.formId}`;
      const { data: responseData } = await execute(
        "POST",
        queryUrl,
        data,
        variables
      );

      return responseData;
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

//#endregion

export {
  useDynamicFormBuilderGetOne,
  useDynamicFormBuilderGetMany,
  useDynamicFormBuilderSave,
  useDynamicFormBuilderDelete,
  useDynamicFormGetOne,
  useDynamicFormGetMany,
  useDynamicFormDataGetOne,
  useDynamicFormDataGetMany,
  useDynamicFormDataSave,
};
