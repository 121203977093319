import MenuForm from "components/config/menu/form";
import PageHeader from "components/PageHeader";
import {
  invariantResponse,
  isNullEmptyOrWhitespace,
  isRequiredPermission,
} from "helpers/common";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router-dom";

const ConfigMenuMutatePage = () => {
  const { id } = useParams();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "menu:write", "api"),
    "User is not authorized to view this page",
    404
  );

  invariantResponse(!isNullEmptyOrWhitespace(id), "Menu ID is required.", 404);

  return (
    <>
      <PageHeader>
        <div className="text-xl">Menu</div>
      </PageHeader>
      <div className="col-span-full">
        <MenuForm id={id} />
      </div>
    </>
  );
};

export default ConfigMenuMutatePage;
