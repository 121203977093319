import RoleForm from "components/config/role/form";
import PageHeader from "components/PageHeader";
import { invariantResponse, isRequiredPermission } from "helpers/common";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router-dom";

const ConfigRoleMutatePage = () => {
  const { id } = useParams();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "user:write", "api"),
    "User is not authorized to view this page",
    404
  );

  return (
    <>
      <PageHeader>
        <div className="text-xl">Create Role</div>
      </PageHeader>
      <div className="space-y-4">
        <RoleForm id={id} />
        {/* <PermissionsButton id={id} /> */}
        {/* <MenuPermissionsButton id={id} /> */}
      </div>
    </>
  );
};

export default ConfigRoleMutatePage;
