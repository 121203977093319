import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import { useUser } from "hooks/useUser";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "lucide-react";
import { MenuItemSchema, useMenuGetOneByNameFromCache } from "hooks/useMenu";
import { isNullEmptyOrWhitespace } from "helpers/common";
import ListSkeleton from "./core/Lists/ListSkeleton";

type MenuProps = {
  menuName: string;
  onClickMenuItem?: () => void;
};

const Menu: React.FC<MenuProps> = ({ menuName, onClickMenuItem }) => {
  const { user } = useUser();
  const { isLoading, data: menu } = useMenuGetOneByNameFromCache({
    enabled:
      !isNullEmptyOrWhitespace(user) && !isNullEmptyOrWhitespace(menuName),
    name: menuName,
  });

  if (isLoading) return <ListSkeleton />;

  if (!menu || !menu.menuItems) return null;

  if (!user) return <div>User not found</div>;

  // function isCurrent(item: (typeof menu.menuItems)[0]): boolean {
  //   if (item.href === window.location.pathname) {
  //     return true;
  //   } else if (item.children && item.children.length > 0) {
  //     return item.children.some((child) => isCurrent(child));
  //   }

  //   return false;
  // }

  return (
    <nav>{buildMenu(menu.menuItems, user?.username, onClickMenuItem)}</nav>
  );
};

export default Menu;

function saveRecentVisit(userId: string, menuId: string) {
  if (!userId || !menuId) {
    return;
  }

  const recentVisits = localStorage.getItem(`rv-${userId}`);
  const recentVisitsJson = JSON.parse(recentVisits || "[]") as string[];
  const recentVisitsUpdated = recentVisitsJson
    .filter((r: string) => r !== menuId)
    .slice(0, 3);
  recentVisitsUpdated.unshift(menuId);
  localStorage.setItem(`rv-${userId}`, JSON.stringify(recentVisitsUpdated));
}

function buildMenu(
  items: MenuItemSchema[],
  userName: string,
  onClickMenuItem?: () => void
) {
  return (
    <ul>
      {items.map((item) => {
        const children = item.children;

        // If the item has no href and no children, we don't show it
        if (item.href === "#" && (!children || children.length === 0)) {
          return null;
        }

        // If there is only one child, we can skip the parent and show the child directly
        if (children && children.length === 1) {
          const child = children[0];
          return (
            <li key={child.id}>
              <Link
                to={child.href}
                className={classNames(
                  "font-semibold flex w-full items-center gap-x-3 rounded-md pl-4 pr-1 py-3 text-left leading-6 group-hover:text-primary"
                )}
                onClick={() => {
                  userName && saveRecentVisit(userName, child.id);
                  onClickMenuItem && onClickMenuItem();
                }}
              >
                {item.title} <span className="text-white/55">&gt;</span>{" "}
                {child.title}
              </Link>
            </li>
          );
        }

        // If there are multiple children, we show the parent and the children
        return (
          <li key={item.id}>
            {children ? (
              <Disclosure as="div" className="group">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        "font-semibold flex w-full items-center gap-x-3 rounded-md pl-4 pr-1 py-3 text-left leading-6 group-hover:text-primary"
                      )}
                    >
                      <div className="grow">{item.title}</div>
                      <ChevronRightIcon
                        className={classNames(
                          open ? "rotate-90" : "",
                          "h-5 w-5 shrink-0 group-hover:text-primary"
                        )}
                        aria-hidden="true"
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                      {buildMenu(children, userName, onClickMenuItem)}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <Link
                to={item.href}
                className={classNames(
                  "font-semibold flex w-full items-center gap-x-3 rounded-md pl-4 pr-1 py-3 text-left leading-6 hover:text-primary"
                )}
                onClick={() => {
                  userName && saveRecentVisit(userName, item.id);
                  onClickMenuItem && onClickMenuItem();
                }}
              >
                {item.title}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
}
