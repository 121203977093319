import { IDashboard } from "components/Dashboard/types";
import { useCallback, useEffect, useState } from "react";
import useFetch from "./useFetch";
import {
  onErrorRequest,
  onErrorUpdateMutation,
  onSuccessfulMutation,
  onSuccessfulRequest,
} from "types";
import { toast } from "react-toastify";
import { authConfig } from "authConfig";

type useDashboardGetManyProps = {
  enabled?: boolean;
  onSuccess?: onSuccessfulRequest;
  onError?: onErrorRequest;
};
export const useDashboardGetMany = ({
  enabled = true,
  onSuccess,
  onError,
}: useDashboardGetManyProps = {}) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const [data, setData] = useState<IDashboard[] | null | undefined>(undefined);

  const fetchData = useCallback(async () => {
    const { data, error } = await execute("GET", "/api/dashboards-get");
    if (authConfig.mode === "b2c" && error === "request not ready") {
      setData(undefined);
      return;
    }

    const dashboards = data?.d ?? [];

    // TODO: parse with zod schema

    setData(dashboards);

    return dashboards;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};

type useDashboardGetOneByIdProps = {
  enabled?: boolean;
  id?: string;
};
export const useDashboardGetOneById = ({
  id,
  enabled,
}: useDashboardGetOneByIdProps) => {
  const { isLoading, error, data } = useDashboardGetMany({ enabled });

  const dashboard = data?.find((dashboard) => dashboard.id === id);

  return { isLoading, error, dashboard };
};

type useDashboardGetByMenuIdProps = {
  enabled?: boolean;
  id?: string;
};
export const useDashboardGetByMenuId = ({
  enabled,
  id,
}: useDashboardGetByMenuIdProps) => {
  const { isLoading, error, data } = useDashboardGetMany({ enabled });

  const filteredDashboards = !!data
    ? data.filter((dashboard) => dashboard.menuId?.toString() === id)
    : [];

  return {
    isLoading,
    error,
    dashboards: filteredDashboards,
  };
};

type useDashboardGetDataProps = {
  enabled?: boolean;
  source: string;
  params: Record<string, unknown>;
};
export const useDashboardGetData = ({
  enabled,
  source,
  params,
}: useDashboardGetDataProps) => {
  const { isLoading, error, execute } = useFetch({
    onError: (error) => {
      console.error(error);
      toast.error(error ?? "Failed to fetch dashboard data");
    },
  });

  const [data, setData] = useState<{
    data: unknown[];
    standards: unknown[];
    notes: {
      ID: string;
      ChartID: string;
      Note: string;
    }[];
  }>({
    data: [],
    standards: [],
    notes: [],
  });

  const fetchData = useCallback(
    async ({
      newSource,
      newParams,
    }: {
      newSource?: string;
      newParams?: Record<string, unknown>;
    }) => {
      const actualSource = newSource ?? source;
      const actualParams = newParams ?? params;

      const paramKeys = Object.keys(actualParams);

      const { data } = await execute(
        "GET",
        `/api/chartdata-get?source=${actualSource}${
          paramKeys.length
            ? `&${paramKeys
                .map((key) => `${key}=${actualParams[key]}`)
                .join("&")}`
            : ""
        }`
      );

      const newData = data?.d ?? [];

      setData(newData);

      return newData;
    },
    [execute, params, source]
  );

  useEffect(() => {
    if (enabled) {
      fetchData({ newParams: params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, fetchData]);

  return { isLoading, error, data, fetch: fetchData };
};

type useDashboardUpdateOrCreateProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useDashboardUpdateOrCreate = ({
  onSuccess,
  onError,
}: useDashboardUpdateOrCreateProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (dashboard: IDashboard) => {
      const { data } = await execute("POST", "/api/dashboard-post", {
        ...dashboard,
        charts: JSON.stringify(dashboard.charts),
      });

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useDashboardDeleteProps = {
  onSuccess?: (response: Response, responseBody: any) => void;
  onError?: (errMessage: string) => void;
};
export const useDashboardDelete = ({
  onSuccess,
  onError,
}: useDashboardDeleteProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async (id: string) => {
      const { data } = await execute("POST", "/api/dashboard-delete", {
        id,
      });

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useDashboardNoteUpdateProps = {
  onSuccess?: onSuccessfulMutation;
  onError?: onErrorUpdateMutation;
};
export const useDashboardNoteUpdate = ({
  onSuccess,
  onError,
}: useDashboardNoteUpdateProps) => {
  const { isLoading, error, execute } = useFetch({
    onSuccess,
    onError,
  });

  const mutate = useCallback(
    async ({
      chartId,
      farmcode,
      house,
      cropdate,
      note,
    }: {
      chartId: string;
      farmcode: string;
      house: string;
      cropdate: string;
      note: string;
    }) => {
      const { data } = await execute("PUT", "/api/dashboard-note-put", {
        chartId,
        farmcode,
        house,
        cropdate,
        note,
      });

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};
