import React, { ErrorInfo } from "react";
import { appInsights } from "../appInsights";
import { parseInvariantError } from "helpers/common";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorData: any;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorData: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    let errorData = { status: null, message: error.message };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errorData = parseInvariantError(error.message);

    return { hasError: true, error, errorData };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log error details to Azure Application Insights
    appInsights.trackException({
      error,
      severityLevel: 3, // Error severity (3 = Error)
      properties: {
        componentStack: errorInfo.componentStack,
        userAgent: navigator.userAgent,
        platform: navigator?.platform,
        url: window.location.href,
        errorData: this.state.errorData,
      },
    });
  }

  handleRefreshPage() {
    // ensure that we aren't refreshing error page
    if (window?.location?.pathname === "/500") {
      window.location.href = "/";
      return;
    }
    window.location.reload();
  }

  handleRedirectToHomepage() {
    window.location.href = "/";
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorData.status === 404) {
        return (
          <div
            id="error-404"
            className="bg-gray-100 min-h-screen font-sans text-base flex flex-col overflow-x-hidden print:bg-white"
          >
            <div className="flex flex-col flex-grow justify-center py-4 px-4 tablet:text-center">
              <h1 className="text-3xl mb-3">Page Not Found (404)!</h1>
              <p className="mb-3">Message: {this.state.errorData.message}</p>
              <p className="mb-3">
                Click to{" "}
                <span
                  className="text-primary cursor-pointer"
                  onClick={this.handleRedirectToHomepage}
                >
                  visit the homepage
                </span>{" "}
                .
              </p>
            </div>
          </div>
        );
      }

      return (
        <div
          id="error-500"
          className="bg-gray-100 min-h-screen font-sans text-base flex flex-col overflow-x-hidden print:bg-white"
        >
          <div className="flex flex-col flex-grow justify-center py-4 px-4 tablet:text-center">
            <h1 className="text-3xl mb-3">Opps - Something went wrong!</h1>
            <p className="mb-3">
              An error occurred while fetching application data.
            </p>
            <p className="mb-3">
              Click to{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={this.handleRefreshPage}
              >
                refresh the page
              </span>{" "}
              and try again,{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={this.handleRedirectToHomepage}
              >
                visit the homepage
              </span>{" "}
              .
            </p>
            <p className="mb-3 font-medium italic">
              If the issue persists, please contact your support team.
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
