import RoleList from "components/config/role/list";
import PageHeader from "components/PageHeader";
import Button from "components/placement/Button";
import { invariantResponse, isRequiredPermission } from "helpers/common";
import { useUser } from "hooks/useUser";
import { PlusCircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ConfigRolePage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  invariantResponse(user, "User not found", 404);

  invariantResponse(
    isRequiredPermission(user?.permissions, "config:admin:users", "page"),
    "User is not authorized to view this page",
    404
  );

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Roles &amp; Permissions</div>
          {isRequiredPermission(user?.permissions, "user:write", "api") && (
            <Button
              type="button"
              variant="primary"
              onClick={() => navigate("new")}
            >
              <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
            </Button>
          )}
        </div>
      </PageHeader>
      <div className="pt-4">
        <RoleList user={user} />
      </div>
    </>
  );
};

export default ConfigRolePage;
