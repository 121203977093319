import { AppDataProvider } from "context/AppDataProvider";
import ConfigProvider from "context/ConfigProvider";
import FarmProvider from "context/FarmProvider";
import FormProvider from "context/FormProvider";
import MenuProvider from "context/MenuProvider";
import { ModalProvider } from "context/ModalProvider";
import { NavProvider } from "context/NavProvider";
import SidebarProvider from "context/SidebarProvider";
import { UserProvider } from "context/UserProvider";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import RootLayout from "./RootLayout";
import LoginPage from "pages/user/login";
import SignupPage from "pages/user/signup";
import DebugPage from "pages/Debug";
import HomePage from "pages/index";
import AdminNonConformanceList from "pages/admin/Audit/NonConformance/ListPage";
import SyncManagerPage from "pages/SyncManager";
import Error404Page from "pages/Error404";
import NetworkStatusProvider from "context/NetworkStatusProvider";
import { useUser } from "hooks/useUser";
import OfflineNotification from "components/OfflineNotification";
import SyncDataProvider from "context/SyncDataProvider";
import SyncNotification from "components/SyncNotification";
import { Suspense, lazy } from "react";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { TooltipProvider } from "components/placement/Tooltip";
import ConfigPage from "pages/config";
import { authConfig } from "authConfig";
import SampleDynamicFormPage from "pages/sample_dynamic_form_page";
import SampleDynamicTableFormPage from "pages/sample_dynamic_table_form_page";
import FormBuilderPage from "pages/config/dynamic_forms/builder";
import ConfigRolePage from "pages/config/role";
import ConfigRoleMutatePage from "pages/config/role/mutate";
import ConfigRolePermissionsPage from "pages/config/role/permissions/index";
import ConfigRolePermissionMutatePage from "pages/config/role/permissions/mutate";
import ConfigRoleMenuPage from "pages/config/role/menus";
import ConfigRoleMenuMutatePage from "pages/config/role/menus/mutate";
import ConfigUserPage from "pages/config/user";
import ConfigUserCreatePage from "pages/config/user/create";
import ConfigUserEditPage from "pages/config/user/edit";
import ConfigStandardsPage from "pages/config/standards";
import ErrorBoundary from "components/ErrorBoundary";
import SchedulePage from "pages/SchedulePage";
import ReportFormPage from "pages/ReportForm";
import DashboardsListPage from "pages/dashboards";
import MediaListPage from "pages/media/ListPage";
import MediaUploadPage from "pages/media/FormsPage";
import AuditReportPage from "pages/farms/AuditReportPage";
import DashboardBuilderPage from "pages/dashboards/builder";
import DashboardBuilderListPage from "pages/dashboards/builder/ListPage";
import ListPage from "pages/farms/ListPage";
import FarmFormsPage from "pages/farms/FormsPage";
import ConfigMenusPage from "pages/config/menus";
import ConfigMenuMutatePage from "pages/config/menus/mutate";
import ConfigMenuItemMutatePage from "pages/config/menus/menuitem/mutate";

const PlanningPlacementPage = lazy(() => import("pages/planning/placement"));
const PlanningPlacementCreatePage = lazy(
  () => import("pages/planning/placement/create")
);
const PlanningPulletChartPage = lazy(
  () => import("pages/planning/placement/pullet_chart")
);
const PlanningLayingChartPage = lazy(
  () => import("pages/planning/placement/laying_chart")
);

export const PrivateRoute: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isSignedIn } = useUser();
  const location = useLocation();

  if (authConfig.mode === "b2c") {
    // Not required to navigate to login page if using B2C
    return <>{children}</>;
  }

  if (!isSignedIn) {
    return <Navigate to="/user/login" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      }
    >
      <Route path="/user/login" element={<LoginPage />} />
      <Route path="/user/signup" element={<SignupPage />} />

      <Route
        path="/"
        element={
          <NetworkStatusProvider>
            <UserProvider>
              <AppDataProvider>
                <ConfigProvider>
                  <FarmProvider>
                    <FormProvider>
                      <MenuProvider>
                        <SidebarProvider>
                          <NavProvider>
                            <ModalProvider>
                              <PrivateRoute>
                                <SyncDataProvider>
                                  <OfflineNotification />
                                  <SyncNotification />
                                  <Suspense fallback={<FieldsetSkeleton />}>
                                    <TooltipProvider>
                                      <RootLayout />
                                    </TooltipProvider>
                                  </Suspense>
                                </SyncDataProvider>
                              </PrivateRoute>
                            </ModalProvider>
                          </NavProvider>
                        </SidebarProvider>
                      </MenuProvider>
                    </FormProvider>
                  </FarmProvider>
                </ConfigProvider>
              </AppDataProvider>
            </UserProvider>
          </NetworkStatusProvider>
        }
      >
        <Route path="/" element={<HomePage />} />
        <Route path="/planning/placement" element={<PlanningPlacementPage />} />
        <Route
          path="/planning/placement-replicate"
          element={<PlanningPlacementCreatePage />}
        />
        <Route
          path="/planning/placement-pullet-chart"
          element={<PlanningPulletChartPage />}
        />
        <Route
          path="/planning/placement-laying-chart"
          element={<PlanningLayingChartPage />}
        />
        <Route
          path="/audit/non-conformances"
          element={<AdminNonConformanceList />}
        />
        <Route path="/farm/production" element={<SchedulePage />} />
        <Route path="/farm/dashboards" element={<DashboardsListPage />} />
        <Route
          path="/farm/reports"
          element={<ReportFormPage module="farm" />}
        />
        <Route
          path="/audit/reports"
          element={<ReportFormPage module="audit" />}
        />
        {/* Ideally use this */}
        {/* <Route
          path="/report/:module"
          element={<ReportFormPage module="audit" />}
          /> */}
        <Route
          path="/dashboard/builder/new"
          element={<DashboardBuilderPage />}
        />
        <Route
          path="/dashboard/builder/:id"
          element={<DashboardBuilderPage />}
        />
        <Route
          path="/dashboard/builder"
          element={<DashboardBuilderListPage />}
        />
        <Route path="/media/library" element={<MediaListPage />} />
        <Route
          path="/media/library/:formId/new"
          element={<MediaUploadPage />}
        />
        <Route
          path="/media/library/:formId/:id"
          element={<MediaUploadPage />}
        />
        <Route path="/:module/:moduleFeatureGroup" element={<ListPage />} />
        <Route
          path="/:module/:moduleFeatureGroup/:formId"
          element={<FarmFormsPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view"
          element={<FarmFormsPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id"
          element={<FarmFormsPage />}
        />
        <Route
          path="/audit/:moduleFeatureGroup/:formId/report/:id"
          element={<AuditReportPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:parentFormId/:parentId"
          element={<FarmFormsPage />}
        />
        <Route
          path="/:module/:moduleFeatureGroup/:formId/:view/:id/:parentFormId/:parentId"
          element={<FarmFormsPage />}
        />
        <Route path="/debug" element={<DebugPage />} />
        <Route path="/sync-manager" element={<SyncManagerPage />} />
        <Route path="/admin/config" element={<ConfigPage />} />
        <Route path="/admin/config/roles" element={<ConfigRolePage />} />
        <Route path="/admin/config/menus" element={<ConfigMenusPage />} />
        <Route
          path="/admin/config/menus/:id"
          element={<ConfigMenuMutatePage />}
        />
        <Route
          path="/admin/config/menus/:menuId/menuitems/:id"
          element={<ConfigMenuItemMutatePage />}
        />
        <Route
          path="/admin/config/roles/new"
          element={<ConfigRoleMutatePage />}
        />
        <Route
          path="/admin/config/roles/:id"
          element={<ConfigRoleMutatePage />}
        />
        <Route
          path="/admin/config/roles/:roleId/permissions"
          element={<ConfigRolePermissionsPage />}
        />
        <Route
          path="/admin/config/roles/:roleId/permissions/new"
          element={<ConfigRolePermissionMutatePage />}
        />
        <Route
          path="/admin/config/roles/:roleId/permissions/:id"
          element={<ConfigRolePermissionMutatePage />}
        />
        <Route
          path="/admin/config/roles/:roleId/menus"
          element={<ConfigRoleMenuPage />}
        />
        <Route
          path="/admin/config/roles/:roleId/menus/:id"
          element={<ConfigRoleMenuMutatePage />}
        />
        <Route
          path="/admin/config/dynamic-forms"
          element={<FormBuilderPage />}
        />
        {/*<Route path="/admin/config/:formId" element={<SampleDynamicFormListPage />} />*/}{" "}
        {/* Show list of dynamic form record */}
        <Route
          path="/admin/config/:formId/new"
          element={<SampleDynamicFormPage />}
        />{" "}
        {/* Show dynamic form new page */}
        <Route
          path="/admin/config/:formId/edit/:id"
          element={<SampleDynamicFormPage />}
        />{" "}
        {/* Show dynamic form edit page */}
        <Route
          path="/admin/config/:formId/editor"
          element={<SampleDynamicTableFormPage />}
        />{" "}
        <Route path="/admin/config/users" element={<ConfigUserPage />} />
        <Route
          path="/admin/config/users/new"
          element={<ConfigUserCreatePage />}
        />
        <Route
          path="/admin/config/users/:id"
          element={<ConfigUserEditPage />}
        />
        <Route
          path="/admin/config/standards"
          element={<ConfigStandardsPage />}
        />
        <Route path="*" element={<Error404Page />} />
      </Route>
    </Route>
  )
);

const Pages = () => {
  return <RouterProvider router={router} />;
};

export default Pages;
