import MenuForm from "components/config/role/menu/form";
import { Alert } from "components/core";
import PageHeader from "components/PageHeader";
import { Skeleton } from "components/ui/skeleton";
import {
  invariantResponse,
  isNullEmptyOrWhitespace,
  isRequiredPermission,
} from "helpers/common";
import { useRoleGetOne } from "hooks/useRole";
import { useUser } from "hooks/useUser";
import { useParams } from "react-router-dom";

const ConfigRoleMenuMutatePage = () => {
  const { roleId, id } = useParams();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "menu:write", "api"),
    "User is not authorized to view this page",
    404
  );

  invariantResponse(roleId, "Role ID is required.", 404);
  invariantResponse(id, "Menu ID is required.", 404);

  const {
    data: role,
    isLoading: isLoadingRoles,
    isFetched: isFetchedRoles,
    error: errorRole,
  } = useRoleGetOne({
    enabled: !isNullEmptyOrWhitespace(roleId),
    id: roleId ?? "",
  });

  if (isLoadingRoles || !isFetchedRoles) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  if (errorRole) {
    return <Alert theme="danger">An error occurred while fetching role.</Alert>;
  }

  if (isNullEmptyOrWhitespace(role)) {
    return <Alert theme="danger">Role not found.</Alert>;
  }

  if (isNullEmptyOrWhitespace(id)) {
    return <Alert theme="danger">Menu ID is required.</Alert>;
  }

  return (
    <>
      <PageHeader>
        <div className="text-xl">
          Edit <span className="italic">{role.name}</span> menu permissions
        </div>
      </PageHeader>
      <div className="col-span-full">
        <MenuForm role={role} menuId={id} />
      </div>
    </>
  );
};

export default ConfigRoleMenuMutatePage;
