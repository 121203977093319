import Card, { CardTitle } from "components/Card";
import { Alert } from "components/core";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import Button from "components/placement/Button";
import {
  RoleMutateSchema,
  roleMutateSchema,
  useRoleForm,
  useRoleGetOne,
  useRoleMutate,
} from "hooks/useRole";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/placement/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { Input } from "components/placement/Input";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { Textarea } from "components/placement/Textarea";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "components/ui/select";

type RoleFormProps = {
  id?: string;
};
const RoleForm = ({ id }: RoleFormProps) => {
  const isNewForm = isNullEmptyOrWhitespace(id);
  const navigate = useNavigate();

  const {
    data: roleForm,
    isLoading: isLoadingRoleForm,
    isFetched: isFetchedRoleForm,
    error: errorRoleForm, // TODO: display error to user
  } = useRoleForm();

  const {
    data: roleData,
    isLoading: isLoadingRole,
    isFetched: isFetchedRole,
  } = useRoleGetOne({
    enabled: !isNewForm && isFetchedRoleForm,
    id: id ?? "",
  });

  const { mutate } = useRoleMutate({
    onSuccess: () => {
      toast.success("Role updated successfully.");

      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RoleMutateSchema>({
    defaultValues: {
      id: id,
      name: "",
      description: "",
      // legacyPermLevel: "",
      farmAccessType: "",
      language: "",
    },
    resolver: zodResolver(roleMutateSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (roleData) {
      resetForm(roleData);
    }
  }, [roleData, resetForm]);

  if (
    isLoadingRoleForm ||
    !isFetchedRoleForm ||
    (!isNewForm && (isLoadingRole || !isFetchedRole))
  ) {
    return <FieldsetSkeleton />;
  }

  if (!isNewForm && !roleData) {
    return <Alert theme="danger">Role not found.</Alert>;
  }

  if (!roleForm) {
    return <Alert theme="danger">Role form not found.</Alert>;
  }

  const onSubmit = async (data: RoleMutateSchema) => {
    console.log("data", data);
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("/admin/config/roles");
  };

  if (errorRoleForm) {
    return <Alert theme="danger">Error loading role form.</Alert>;
  }

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <Card>
            <CardTitle>{isNewForm ? "Create Form" : "Edit Form"}</CardTitle>
            <div className="space-y-4">
              <FormField
                control={reactForm.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Role Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription className="text-xs text-gray-500">
                      {id}
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name="language"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Language</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {roleForm.languagesOptions.map((o) => (
                            <SelectItem
                              key={o.value}
                              value={o.value.toString()}
                            >
                              {o.text}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription className="text-xs text-gray-500">
                      Defines what language is used to display information at
                      site, customer or supplier, outlet or user levels.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={reactForm.control}
                name="farmAccessType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Farm Access Type</FormLabel>
                    <FormControl>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="-- Select --" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {roleForm.farmAccessOptions.map((o) => (
                            <SelectItem key={o.value} value={o.value}>
                              {o.text}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormDescription className="text-xs text-gray-500">
                      Users in the group can be set to give them permissions to
                      select farms associated with the group code displayed. If
                      none is selected then users can only select the farms that
                      they are individually assigned to in user maintenance.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Card>
          <div className="flex justify-between">
            <Button
              type="button"
              variant="default"
              size="sm"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <div className="space-x-4">
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </>
  );
};

export default RoleForm;
