import { Checkbox } from "components/placement/Checkbox";
import {
  MenuItemFormPermissionMutateSchema,
  MenuItemSchema,
} from "hooks/useMenu";
import type { Permission } from "hooks/useRole";
import React from "react";
import { type CheckedState } from "@radix-ui/react-checkbox";
import { type UseFormReturn } from "react-hook-form";
import { isNullEmptyOrWhitespace } from "helpers/common";
import { CheckIcon, XIcon } from "lucide-react";

function MenuItemFormPermissionEditor({
  title,
  menuItem,
  availablePermissions,
  selectedPermissions,
  reactForm,
  editable = false,
}: {
  title: React.ReactNode;
  menuItem: MenuItemSchema;
  availablePermissions: Permission[];
  selectedPermissions: Record<string, boolean>;
  reactForm: UseFormReturn<MenuItemFormPermissionMutateSchema>;
  editable?: boolean;
}) {
  const handlePermissionCheckboxChange = (
    checked: CheckedState,
    formPermissionId: string
  ) => {
    if (!editable) return;

    const newPermissions = { ...selectedPermissions }; // Ensure we are not mutating state directly
    if (checked) {
      newPermissions[formPermissionId] = true;
    } else {
      newPermissions[formPermissionId] = false;
    }

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  if (isNullEmptyOrWhitespace(availablePermissions)) {
    return null;
  }

  return (
    <div className="pl-6 my-2 text-xs">
      <div className="text-gray-500">{title}:</div>
      <div className="py-2 space-y-2">
        {availablePermissions.map((map) => (
          <div key={map.id} className="flex items-center space-x-2">
            {editable ? (
              <Checkbox
                checked={selectedPermissions[map.id] === true}
                onCheckedChange={(checked) =>
                  handlePermissionCheckboxChange(checked, map.id)
                }
              />
            ) : selectedPermissions[map.id] === true ? (
              <CheckIcon className="h-3 w-3 text-success-600" />
            ) : (
              <XIcon className="h-3 w-3 text-danger-600" />
            )}
            <span>{map.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuItemFormPermissionEditor;
