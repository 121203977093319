import Card from "components/Card";
import { Alert } from "components/core";
import Button from "components/placement/Button";
import {
  Permission,
  rolePermissionsMutateSchema,
  RolePermissionsMutateSchema,
  type RoleWithPermissions,
  useRolePermissionsMutate,
} from "hooks/useRole";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "components/placement/Form";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { MenuItemSchema, useMenuGetOneById } from "hooks/useMenu";
import { Checkbox } from "components/placement/Checkbox";
import { type UserMeSchema, useUser } from "hooks/useUser";
import { type CheckedState } from "@radix-ui/react-checkbox";
import {
  isNullEmptyOrWhitespace,
  isRequiredPermission,
  tryInvalidateApiCache,
} from "helpers/common";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "components/ui/collapsible";
import { ChevronRightIcon } from "lucide-react";
import MenuItemFormPermissionEditor from "components/config/menuitem/_permission.editor";
import { Skeleton } from "components/ui/skeleton";

function toggleAssociatedPermissions(
  checked: boolean,
  currentMenuItem: MenuItemSchema,
  selectedPermsMutatable: Record<string, boolean>
) {
  const checkedPermId = currentMenuItem?.permission?.id;

  if (!checkedPermId) {
    return;
  }

  const assocPerms = getAssocPerms(currentMenuItem);

  for (const p of assocPerms) {
    if (checked) {
      selectedPermsMutatable[p.id] = true;
    } else {
      selectedPermsMutatable[p.id] = false;
    }
  }
}

const RoleForm = ({
  role,
  menuId,
}: {
  role: RoleWithPermissions;
  menuId: string;
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const {
    data: menuData,
    isLoading: isLoadingMenus,
    isFetched: isFetchedMenus,
  } = useMenuGetOneById({
    enabled: !isNullEmptyOrWhitespace(user),
    id: menuId,
    includePermissions: true,
  });

  const availPerms = React.useMemo(() => {
    if (menuData?.menuItems) {
      return flattenPermissions(menuData.menuItems);
    }
    return [];
  }, [menuData]);

  const { mutate } = useRolePermissionsMutate({
    onSuccess: async () => {
      toast.success("Role menu permissions updated successfully.");

      await tryInvalidateApiCache("/api/menus-get");

      navigate("./..");
    },
    onError: (errMessage) => {
      toast.error(errMessage);
    },
  });

  const reactForm = useForm<RolePermissionsMutateSchema>({
    defaultValues: {
      id: role.id,
      permissions: {} as RolePermissionsMutateSchema["permissions"],
    },
    resolver: zodResolver(rolePermissionsMutateSchema),
  });
  const resetForm = reactForm.reset;

  React.useEffect(() => {
    if (availPerms && role) {
      const newFormData = {
        id: role.id,
        // { permissionId1: true, permissionId2: true }
        permissions: availPerms.reduce((acc: Record<string, boolean>, ap) => {
          // mark the permission as checked if in role permissions
          acc[ap.id] = role.permissions.some((rp) => rp.id === ap.id);
          return acc;
        }, {}),
      };
      resetForm(newFormData);
    }
  }, [role, resetForm, availPerms]);

  if (isLoadingMenus || !isFetchedMenus) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  if (!role) {
    return <Alert theme="danger">Role not found.</Alert>;
  }

  if (!menuData) {
    return <Alert theme="danger">Menu not found.</Alert>;
  }

  if (!user) {
    return <Alert theme="danger">User not found.</Alert>;
  }

  const onSubmit = async (data: RolePermissionsMutateSchema) => {
    await mutate(data);
  };

  const handleClickCancel = () => {
    navigate("/admin/config/roles");
  };

  return (
    <>
      <Form {...reactForm}>
        <form
          className="mt-4 space-y-4"
          onSubmit={reactForm.handleSubmit(onSubmit)}
          noValidate
        >
          <Card>
            <div className="flex justify-between">
              <Button
                type="button"
                variant="default"
                size="sm"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
              <div className="space-x-4">
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </div>
            </div>
            <nav className="pt-4">
              {!!menuData.menuItems && (
                <MenuBuilder
                  reactForm={reactForm}
                  menuItems={menuData.menuItems}
                  permissions={availPerms}
                  user={user}
                />
              )}
            </nav>
          </Card>
        </form>
      </Form>
    </>
  );
};

export default RoleForm;

const MenuBuilder: React.FC<{
  reactForm: UseFormReturn<RolePermissionsMutateSchema>;
  menuItems: MenuItemSchema[];
  currentMenuId?: string;
  permissions: Permission[];
  user: UserMeSchema;
}> = ({ reactForm, menuItems, currentMenuId, permissions, user }) => {
  const selectedPerms = reactForm.watch("permissions"); // Watch for changes in permissions
  const currentMenuItem = findMenuItem(menuItems, currentMenuId);
  const childMenuItems = currentMenuItem
    ? currentMenuItem?.children
    : menuItems;

  const handlePagePermissionCheckboxChange = (
    checked: CheckedState,
    menuItem: MenuItemSchema
  ) => {
    if (!menuItem.permission) {
      return;
    }

    const newPermissions = Object.assign({}, selectedPerms); // Ensure we are not mutating state directly
    const currentPermId = menuItem?.permission?.id;
    console.log(menuItem.title, currentPermId);
    if (!currentPermId) {
      return;
    }

    if (checked) {
      console.log(
        `Enabling ${menuItem.title} with permission ${currentPermId}`
      );
      newPermissions[currentPermId] = true;
    } else {
      console.log(
        `Disabling ${menuItem.title} with permission ${currentPermId}`
      );
      newPermissions[currentPermId] = false;
    }

    enableParentPermissions(newPermissions, menuItem, menuItems);

    toggleAssociatedPermissions(
      newPermissions[currentPermId],
      menuItem,
      newPermissions
    );

    reactForm.setValue("permissions", newPermissions, {
      shouldValidate: true,
    });
  };

  const enableParentPermissions = (
    permissions: Record<string, boolean>,
    menuItem: MenuItemSchema,
    allMenuItems: MenuItemSchema[]
  ) => {
    const parentId = menuItem.parentId;

    if (!parentId) {
      return;
    }

    const parentMenuItem = findMenuItem(allMenuItems, parentId);

    if (!parentMenuItem || !parentMenuItem.permission) {
      return;
    }

    const hasEnabledChildren = hasEnabledChildrenPermissions(
      parentMenuItem,
      permissions,
      allMenuItems
    );

    if (hasEnabledChildren) {
      permissions[parentMenuItem.permission.id] = true;
      console.log(
        `Enabling parent ${parentMenuItem.title} with permission id: ${parentMenuItem.permission.id}`
      );
    } else {
      console.log(
        `Disabling parent ${parentMenuItem.title} with permission id: ${parentMenuItem.permission.id}`
      );
      permissions[parentMenuItem.permission.id] = false;
    }

    enableParentPermissions(permissions, parentMenuItem, allMenuItems);
  };

  const getCountCheckedFlattenPermissions = (
    flattenPermissions: Permission[]
  ) => {
    return flattenPermissions.reduce((count, p) => {
      if (p.id && selectedPerms[p.id] === true) {
        return count + 1;
      }

      return count;
    }, 0);
  };

  if (!childMenuItems) {
    return null;
  }

  return (
    <ul className="divide divide-y">
      {childMenuItems.map((item, index) => {
        const flattenedPermissions = flattenPermissions(item.children ?? []);
        const countCheckedPermsInGroup = item.children
          ? getCountCheckedFlattenPermissions(flattenedPermissions)
          : 0;
        const totalPermsInGroup = flattenedPermissions.length;

        // const assocPerms = flattenPermissions([item]);
        const assocPerms = getAssocPerms(item);
        const availFormPerms = assocPerms.filter((p) => p.group === "form");
        const availApiPerms = assocPerms.filter((p) => p.group === "api");

        const countCheckedAssocPerms = assocPerms.reduce((count, p) => {
          if (p.id && selectedPerms[p.id] === true) {
            return count + 1;
          }

          return count;
        }, 0);

        const totalAssocPerms = assocPerms.length;

        const hasAssocPerms = totalAssocPerms > 0;

        return (
          <li key={item.id}>
            <Collapsible
              className="group/collapsible [&[data-state=open]>div>div>svg]:text-primary [&[data-state=open]>div>div>svg]:rotate-90"
              defaultOpen={index === 0}
            >
              {item.children && item.children.length ? (
                <>
                  <div className="flex flex-row items-center space-x-2 py-2">
                    <Checkbox
                      checked={
                        countCheckedPermsInGroup === totalPermsInGroup
                          ? true
                          : countCheckedPermsInGroup > 0
                          ? "indeterminate"
                          : false
                      }
                      onCheckedChange={(checked) => {
                        console.log(`checked: ${checked} for ${item.title}`);
                        handlePagePermissionCheckboxChange(checked, item);
                      }}
                    />
                    <CollapsibleTrigger asChild>
                      <div className="flex items-center justify-between space-x-4 px-4">
                        <div className="space-y-1 leading-none">
                          {item.title}
                          {totalPermsInGroup > 0 && (
                            <span className="text-xs">
                              {" "}
                              ({countCheckedPermsInGroup}/{totalPermsInGroup})
                            </span>
                          )}
                        </div>
                        <ChevronRightIcon className="transition-transform w-4 h-4" />
                      </div>
                    </CollapsibleTrigger>
                  </div>
                  <CollapsibleContent>
                    <ul className="pl-6 pr-2">
                      <MenuBuilder
                        reactForm={reactForm}
                        menuItems={menuItems}
                        currentMenuId={item.id}
                        permissions={permissions}
                        user={user}
                      />
                    </ul>
                  </CollapsibleContent>
                </>
              ) : (
                <Collapsible
                  className="group/collapsible [&[data-state=open]>div>div>svg]:text-primary [&[data-state=open]>div>div>svg]:rotate-90"
                  defaultOpen={false}
                >
                  <div className="flex flex-row items-center space-x-2 my-2 text-[0.85rem]">
                    <Checkbox
                      checked={
                        !item.permission ||
                        selectedPerms[item.permission.id] === true
                          ? countCheckedAssocPerms === totalAssocPerms
                            ? true
                            : countCheckedAssocPerms > 0
                            ? "indeterminate"
                            : false
                          : false
                      }
                      onCheckedChange={(checked) => {
                        handlePagePermissionCheckboxChange(checked, item);
                      }}
                      disabled={!item.permission}
                      title={!item.permission ? "No permission defined" : ""}
                    />
                    <CollapsibleTrigger asChild>
                      <div className="flex items-center justify-between space-x-4 px-4">
                        <div className="space-y-1 leading-none">
                          {item.title}
                          {totalAssocPerms > 0 && (
                            <span className="text-xs">
                              {" "}
                              ({countCheckedAssocPerms}/{totalAssocPerms})
                            </span>
                          )}
                        </div>
                        {hasAssocPerms &&
                          isRequiredPermission(
                            user?.permissions,
                            "rolepermissions:write",
                            "api"
                          ) && (
                            <ChevronRightIcon className="transition-transform w-4 h-4" />
                          )}
                      </div>
                    </CollapsibleTrigger>
                  </div>
                  <CollapsibleContent>
                    {hasAssocPerms &&
                      isRequiredPermission(
                        user?.permissions,
                        "rolepermissions:write",
                        "api"
                      ) && (
                        <div className="flex flex-row items-start space-x-3">
                          <MenuItemFormPermissionEditor
                            key={`${item.id}-form-permissions`}
                            title="Required Form permissions"
                            menuItem={item}
                            availablePermissions={availFormPerms}
                            selectedPermissions={selectedPerms}
                            reactForm={reactForm}
                          />
                          <MenuItemFormPermissionEditor
                            key={`${item.id}-api-permissions-2`}
                            title="Required API permissions"
                            menuItem={item}
                            availablePermissions={availApiPerms}
                            selectedPermissions={selectedPerms}
                            reactForm={reactForm}
                          />
                        </div>
                      )}
                  </CollapsibleContent>
                </Collapsible>
              )}
            </Collapsible>
          </li>
        );
      })}
    </ul>
  );
};

function flattenPermissions(menuItems: MenuItemSchema[]) {
  if (!menuItems) return [] as Permission[];

  const permissionMap = new Map<string, Permission>();

  menuItems.forEach((item) => {
    if (item.permission) {
      permissionMap.set(item.permission.id, item.permission);
    }

    if (item.children) {
      const childPermissions = flattenPermissions(item.children);
      for (const p of childPermissions) {
        permissionMap.set(p.id, p);
      }
    }

    if (item.formPermissions) {
      item.formPermissions.forEach((p) => {
        permissionMap.set(p.id, {
          ...p,
          group: "form",
        });
      });
    }

    if (item.apiPermissions) {
      item.apiPermissions.forEach((p) => {
        permissionMap.set(p.id, {
          ...p,
          group: "api",
        });
      });
    }
  });

  return Array.from(permissionMap.values()) as Permission[];
}

function getAssocPerms(currentMenuItem: MenuItemSchema) {
  const assocPerms = flattenPermissions([currentMenuItem]);

  // delete current permission
  assocPerms.splice(
    assocPerms.findIndex((p) => p.id === currentMenuItem.permission?.id),
    1
  );

  return assocPerms;
}

function findMenuItem(
  items: MenuItemSchema[],
  menuItemId?: string
): MenuItemSchema | undefined {
  if (!menuItemId) return;

  for (const item of items) {
    if (item.id === menuItemId) return item;
    if (item.children) {
      const foundItem = findMenuItem(item.children, menuItemId);
      if (foundItem) return foundItem;
    }
  }

  return;
}

function hasEnabledChildrenPermissions(
  menuItem: MenuItemSchema,
  permissions: Record<string, boolean>,
  allMenuItems: MenuItemSchema[]
) {
  if (menuItem.children) {
    for (const child of menuItem.children) {
      if (!child.permission) {
        continue;
      }

      if (permissions[child.permission.id]) {
        return true;
      }
      if (hasEnabledChildrenPermissions(child, permissions, allMenuItems)) {
        return true;
      }
    }
  }
  return false;
}
