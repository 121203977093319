import { useEffect, useState, useRef } from "react";
import AppUpdateNotification from "components/AppUpdateNotification";
import BackgroundSyncNotification from "components/BackgroundSyncNotification";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import { CustomNavigationClient } from "routes/CustomNavigationClient";
import Pages from "routes/Pages";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { AuthError, EventType } from "@azure/msal-browser";
import { b2cPolicies } from "authConfig";
// import { insertSampleFormValuesToCache } from "hooks/useFormData";

import { MsalProvider } from "@azure/msal-react";
import { authConfig } from "authConfig";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App({ instance }) {
  // // See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/typescript-sample/src/App.tsx
  // // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  // const navigate = useNavigate();
  // const navigationClient = new CustomNavigationClient(navigate);
  // instance.setNavigationClient(navigationClient);

  const [swStatus, setSwStatus] = useState("");

  const waitingWorkerRef = useRef(null);

  const handleSwUpdateAvailable = (ev) => {
    console.log("update available");
    waitingWorkerRef.current = ev.detail.waiting;
    setSwStatus("update_available");
  };

  const handleSwInstalling = () => {
    console.log("installing");
    setSwStatus("installing");
  };

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleSwUpdateAvailable
    );

    document.removeEventListener("serviceWorkerInstalling", handleSwInstalling);

    // insertSampleFormValuesToCache();

    return () => {
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleSwUpdateAvailable
      );
      document.removeEventListener(
        "serviceWorkerInstalling",
        handleSwInstalling
      );
    };
  }, []);

  useEffect(() => {
    if (swStatus === "installed") {
      setTimeout(() => {
        setSwStatus("");
      }, 3000);
    }
  }, [swStatus]);

  const handleUpdateApp = () => {
    setSwStatus("installing");

    // delay to allow UI to update
    setTimeout(() => {
      waitingWorkerRef.current.postMessage({ type: "SKIP_WAITING" });
    }, 2000);

    waitingWorkerRef.current.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        console.log("activated");
        setSwStatus("installed");

        // Remove the event listener since it's no longer needed
        waitingWorkerRef.current = null;

        window.location.reload();
      }
    });

    // reset status after 10 seconds
    setTimeout(() => {
      if (!isNullEmptyOrWhitespace(swStatus)) {
        console.warn("Resetting SW status due to timeout");
        setSwStatus("");
      }
    }, 10000);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppUpdateNotification status={swStatus} onClick={handleUpdateApp} />
      <BackgroundSyncNotification />
      <MsalProviderOptional instance={instance}>
        <Pages />
      </MsalProviderOptional>
    </>
  );
}

function MsalProviderOptional({ instance, children }) {
  // if (authConfig.mode === "b2c") {
  // import("@azure/msal-react")
  //   .then((module) => {
  //     MsalProvider = module.MsalProvider;
  //   })
  //   .catch((err) => {
  //     console.error("Error importing MsalProvider", err);
  //   });
  // }

  if (instance && authConfig.mode === "b2c") {
    // We do this here so that we can display the error message to the user
    instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
      ) {
        // const username = event.payload.account.username;
        // toast.success(`Welcome, ${username}!`);
      } else if (
        event.eventType === EventType.LOGIN_FAILURE ||
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        event.eventType === EventType.SSO_SILENT_FAILURE
      ) {
        if (event.error instanceof AuthError) {
          if (event.error.errorMessage.startsWith("AADB2C90091")) {
            // User cancelled the login
            toast.info("Login cancelled. Redirecting to login page...", {
              autoClose: 3000,
              onClose: () => {
                window.location.href = "/user/login";
              },
              onClick: () => {
                window.location.href = "/user/login";
              },
            });
          } else if (event.error.errorMessage.startsWith("AADB2C90118")) {
            // User clicked forgot password
            // Redirect to forgot password page
            instance.loginRedirect({
              authority: b2cPolicies.authorities.forgotPassword.authority,
            });
          } else {
            toast.error(event.error?.message ?? "An error occurred", {
              autoClose: 5000,
              onClose: () => {
                window.location.href = "/user/login";
              },
              onClick: () => {
                window.location.href = "/user/login";
              },
            });
          }
        }
      }
    });

    return (
        <MsalProvider instance={instance}>{children}</MsalProvider>
    );
  }

  return <>{children}</>;
}
