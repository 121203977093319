import RolePermissionList from "components/config/role/permission/list";
import EmptyState from "components/EmptyState";
import { LoadingOrError } from "components/LoadingOrError";
import PageHeader from "components/PageHeader";
import Button from "components/placement/Button";
import {
  invariantResponse,
  isNullEmptyOrWhitespace,
  isRequiredPermission,
} from "helpers/common";
import { useRoleGetOne } from "hooks/useRole";
import { useUser } from "hooks/useUser";
import { PlusCircleIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

const ConfigRolePermissionsPage = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { user } = useUser();

  invariantResponse(
    isRequiredPermission(user?.permissions, "rolepermissions:write", "api"),
    "User is not authorized to view this page",
    404
  );

  const enabled = !isNullEmptyOrWhitespace(roleId);
  const { data: role, error } = useRoleGetOne({
    enabled,
    id: roleId ?? "",
  });
  const isReady = role !== undefined;

  return (
    <>
      <PageHeader>
        <div className="flex flex-row justify-between">
          <div className="text-xl">Edit role permissions - {role?.name}</div>
          <Button
            type="button"
            variant="primary"
            onClick={() => navigate("new")}
          >
            <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
          </Button>
        </div>
      </PageHeader>
      <div className="col-span-full">
        <LoadingOrError isReady={isReady} error={error}>
          {role === null && <EmptyState />}
          {!!role && <RolePermissionList role={role} />}
        </LoadingOrError>
      </div>
    </>
  );
};

export default ConfigRolePermissionsPage;
